import { lazy } from "react";

// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import GuestGuard from "utils/route-guard/GuestGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { ProjectProvider } from "contexts/ProjectContext";

// import proponent views
import ProponentRoutes from "./ProponentRoutes";
import StatusGuard from "utils/route-guard/StatusGuard";

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));
const NotFound = Loadable(lazy(() => import("views/pages/maintenance/Error")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <StatusGuard>
        <ProjectProvider>
          <MainLayout />
        </ProjectProvider>
      </StatusGuard>
    </AuthGuard>
  ),
  children: [
    ...ProponentRoutes,
    {
      path: "/sample-page",
      element: <SamplePage />,
    },
    {
      path: "/404",
      element: <NotFound />,
    },
    {
      path: "/*",
      element: <NotFound />,
    },
  ],
};

export default MainRoutes;
