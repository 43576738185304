/**
 * axios setup to use mock service
 */

import axios from "axios";

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || error)
);

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.get(url, { ...config });

  return res.data;
};

export const convertToFormData = (data) => {
  const formData = new FormData();
  for (const field in data) {
    if (Array.isArray(data[field])) {
      for (let i = 0; i < data[field].length; i += 1) {
        formData.append(field, data[field][i]);
      }
    } else {
      formData.append(field, data[field]);
    }
  }
  return formData;
};

export const endpoints = {
  dataroom: {
    folder: {
      create: (dataRoomId) => `/dev-service/data-room/${dataRoomId}/folder`,
      delete: (dataRoomId, folderId) =>
        `/dev-service/data-room/${dataRoomId}/folder/${folderId}`,
      update: (dataRoomId, folderId) =>
        `/dev-service/data-room/${dataRoomId}/folder/${folderId}`,
      details: (dataRoomId, folderId) =>
        `/dev-service/data-room/${dataRoomId}/folder/${folderId}`,
    },
    file: {
      create: (dataRoomId) => `/dev-service/data-room/${dataRoomId}/file`,
      delete: (folderId, fileId) =>
        `/dev-service/data-room/folder/${folderId}/file/${fileId}`,
      update: (folderId, fileId) =>
        `/dev-service/data-room/folder/${folderId}/file/${fileId}`,
      details: (folderId, fileId) =>
        `/dev-service/data-room/folder/${folderId}/file/${fileId}`,
    },
  },
};

export default axiosServices;
