import { lazy } from "react";
import Loadable from "ui-component/Loadable";

// import proponent views
// dashboard views
const ProponentDashboard = Loadable(
  lazy(() => import("views/proponent/dashboard"))
);
// project views
const OnboardProponentProjects = Loadable(
  lazy(() => import("views/proponent/projects/onboarding"))
);
const ListProponentProjects = Loadable(
  lazy(() => import("views/proponent/projects/projectDetails"))
);
// stakeholders views
const ListProjectStakehoders = Loadable(
  lazy(() => import("views/proponent/stakeholders"))
);
const ProponentCookstoves = Loadable(
  lazy(() => import("views/proponent/cookstoves/"))
);
const ProponentCookstoveLocations = Loadable(
  lazy(() => import("views/proponent/coockstoves-locations"))
);
const ProponentEmissionAvoidance = Loadable(
  lazy(() => import("views/proponent/emission-avoidance"))
);
const ProponentEmissionAvoidanceAdd = Loadable(
  lazy(() => import("views/proponent/emission-avoidance/parameters"))
);
const ProponentAnalytics = Loadable(
  lazy(() => import("views/proponent/analytics"))
);
const ProponentUserSupportList = Loadable(
  lazy(() => import("views/proponent/user-support/list"))
);
const ProponentUserSupportEdit = Loadable(
  lazy(() => import("views/proponent/user-support/edit"))
);

const ProponentDevicePage = Loadable(
  lazy(() => import("views/proponent/cookstoves/DevicePage"))
);

const ProponentUserSupportCreate = Loadable(
  lazy(() => import("views/proponent/user-support/create"))
);

const ProponentConsumersList = Loadable(
  lazy(() => import("views/proponent/consumers"))
);

const Test = Loadable(lazy(() => import("views/proponent/analytics")));

const AlertsPage = Loadable(lazy(() => import("views/proponent/alerts")));
const AddConsumer = Loadable(
  lazy(() => import("views/proponent/cookstoves/consumer"))
);

const TransferCookstove = Loadable(
  lazy(() => import("views/proponent/cookstoves/consumer/transfer-cookstove"))
);


// ===========================|| PROPONENT ROUTES ||=========================== //

const ProponentRoutes = [
  // dashboard route
  {
    path: "/proponent/dashboard",
    element: <ProponentDashboard />,
  },
  // project routes
  {
    path: "/proponent/projects/onboarding",
    element: <OnboardProponentProjects />,
  },
  {
    path: "/proponent/projects/list",
    element: <ListProponentProjects />,
  },
  // stakeholders routes
  {
    path: "/proponent/stakeholders/",
    element: <ListProjectStakehoders />,
  },
  {
    path: "/proponent/cookstoves",
    element: <ProponentCookstoves />,
  },
  {
    path: "/proponent/cookstoves/:deviceId",
    element: <ProponentDevicePage />,
  },
  {
    path: "/proponent/cookstove-locations",
    element: <ProponentCookstoveLocations />,
  },
  {
    path: "/proponent/emission-avoidance",
    element: <ProponentEmissionAvoidance />,
  },
  {
    path: "/proponent/emission-avoidance/add",
    element: <ProponentEmissionAvoidanceAdd />,
  },
  {
    path: "/proponent/analytics",
    element: <ProponentAnalytics />,
  },
  {
    path: "/proponent/user-support/list",
    element: <ProponentUserSupportList />,
  },
  {
    path: "/proponent/user-support/edit/:id",
    element: <ProponentUserSupportEdit />,
  },
  {
    path: "/proponent/user-support/create/",
    element: <ProponentUserSupportCreate />,
  },
  {
    path: "/proponent/consumers",
    element: <ProponentConsumersList />,
  },
  {
    path: "/proponent/test",
    element: <Test />,
  },
  {
    path: "/proponent/consumers/add",
    element: <AddConsumer />,
  },
  {
    path: "/proponent/alerts",
    element: <AlertsPage />,
  },
  {
    path: "/proponent/consumers/transfer",
    element: <TransferCookstove />,
  },
];

export default ProponentRoutes;
