import { createContext, useEffect, useState } from "react";
import { useGetProjects } from "api/project";
import useAuth from "hooks/useAuth";

const initialState = {
  project: null,
};

const ProjectContext = createContext(initialState);

export function ProjectProvider({ children }) {
  const { user } = useAuth();

  const { projects, projectsLoading, projectsError } = useGetProjects(user?._id);

  const [project, setProject] = useState(initialState.project);

  useEffect(() => {
    if (projects && projects.length > 0) {
      setProject(projects[0]);
    }
  }, [projects]);

  const updateProject = (projectOrId) => {
    if (typeof projectOrId === "string") {
      const foundProject = projects.find((p) => p._id === projectOrId);
      if (foundProject) {
        setProject(foundProject);
      }
    } else {
      setProject(projectOrId);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        project,
        projects,
        projectsLoading,
        projectsError,
        setProject: updateProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

export default ProjectContext;
