import axios from "../../utils/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../index";

const initialState = {
  status: "idle",
  error: null,
  projects: [],
};

// Async thunk to fetch projects
export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async () => {
    try {
      const response = await axios.get("/projects-service/projects");
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const filterProjects = createAsyncThunk(
  "project/filterProjects",
  async (filter, { getState }) => {
    const response = await axios.post("/originator/projects/filter", filter);
    return response.data;
  }
);

export function getOriginatorProjects() {
  return async () => {
    try {
      const response = await axios.get("/originator/projects/list");
      // dispatch(slice.actions.getProjectsSuccess(response.data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async (id) => {
    const response = await axios.delete(`/originator/projects/${id}`);
    return response.data;
  }
);
// Slice
const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = "idle";
        state.projects = action.payload;
        state.error = null;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });
  },
});

export default projectsSlice.reducer;
