// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconVariablePlus,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconUserCircle,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconCooker,
  IconCloudRain,
  IconMapPins,
  IconChartBar,
  IconLayoutDashboard,
  IconHomeEco,
  IconDeviceDesktopAnalytics,
  IconMessageChatbot,
  IconAlertTriangle,
} from "@tabler/icons";

// constant
const icons = {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconVariablePlus,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconUserCircle,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconCloudRain,
  IconCooker,
  IconMapPins,
  IconChartBar,
  IconLayoutDashboard,
  IconHomeEco,
  IconDeviceDesktopAnalytics,
  IconMessageChatbot,
  IconAlertTriangle,
};

// ==============================|| PROPONENT MENU ITEMS ||============================== //

const proponent = {
  id: "proponent",
  title: <FormattedMessage id="proponent" defaultMessage=" " />,
  icon: icons.IconApps,
  type: "group",
  children: [
    {
      id: "proponent_dashboard",
      title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
      type: "item",
      url: "/proponent/dashboard",
      icon: icons.IconLayoutDashboard,
      breadcrumbs: false,
      permissions: ["view-dashboard"],
    },
    {
      id: "proponent_projects",
      title: <FormattedMessage id="Projects" defaultMessage="My Project" />,
      type: "item",
      breadcrumbs: false,
      icon: icons.IconHomeEco,
      url: "/proponent/projects/list",
      permissions: ["view-my-project"],
    },
    // {
    //   id: "project_stakeholders",
    //   title: (
    //     <FormattedMessage id="Stakeholders" defaultMessage="Stakeholders" />
    //   ),
    //   type: "collapse",
    //   breadcrumbs: false,
    //   icon: icons.IconUsers,
    //   children: [
    //     {
    //       id: "create_stakeholder",
    //       title: (
    //         <FormattedMessage
    //           id="Add Stakeholder"
    //           defaultMessage="Add Stakeholder"
    //         />
    //       ),
    //       type: "item",
    //       icon: icons.IconUserPlus,
    //       url: "/proponent/stakeholders/create",
    //       breadcrumbs: false,
    //     },
    //     {
    //       id: "stakeholder_list",
    //       title: (
    //         <FormattedMessage
    //           id="Stakeholder List"
    //           defaultMessage="Stakeholder List"
    //         />
    //       ),
    //       type: "item",
    //       url: "/proponent/stakeholders/list",
    //       icon: icons.IconUserCheck,
    //       breadcrumbs: false,
    //       external: true,
    //     },
    //   ],
    // },
    {
      id: "proponent_emission_avoidance",
      title: (
        <FormattedMessage
          id="emission_avoidance"
          defaultMessage="Emission Avoidance"
        />
      ),
      type: "collapse",
      url: "/proponent/emission-avoidance",
      icon: icons.IconCloudRain,
      breadcrumbs: false,
      permissions: ["view-project-emission-avoidance"],
      children: [
        {
          id: "add_emission_avoidance_parameters",
          title: (
            <FormattedMessage
              id="Add Parameters"
              defaultMessage="Add Parameters"
            />
          ),
          type: "item",
          icon: icons.IconVariablePlus,
          url: "/proponent/emission-avoidance/add",
          breadcrumbs: false,
          permissions: ["add-parameters"],
        },
        {
          id: "project_emission_avoidance",
          title: (
            <FormattedMessage
              id="Project Emission Avoidance"
              defaultMessage="Project Emission Avoidance"
            />
          ),
          type: "item",
          icon: icons.IconCloudRain,
          url: "/proponent/emission-avoidance",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "proponent_cookstoves",
      title: <FormattedMessage id="cookstoves" defaultMessage="Cookstoves" />,
      type: "item",
      url: "/proponent/cookstoves",
      icon: icons.IconCooker,
      breadcrumbs: false,
      permissions: ["view-cookstoves"],
    },
    {
      id: "proponent_cookstove_locations",
      title: (
        <FormattedMessage
          id="cookstove_locations"
          defaultMessage="Cookstove Locations"
        />
      ),
      type: "item",
      url: "/proponent/cookstove-locations",
      icon: icons.IconMapPins,
      breadcrumbs: false,
      permissions: ["view-cookstove-locations"],
    },
    {
      id: "proponent_analytics",
      title: <FormattedMessage id="analytics" defaultMessage="Analytics" />,
      type: "item",
      url: "/proponent/analytics",
      icon: icons.IconDeviceDesktopAnalytics,
      breadcrumbs: false,
      permissions: ["view-analytics"],
    },
    {
      id: "proponent_user_support",
      title: (
        <FormattedMessage id="user-support" defaultMessage="User Support" />
      ),
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconMessageChatbot,
      defaultOpen: true,
      permissions: ["view-user-support"],
      children: [
        {
          id: "create_user_support",
          title: (
            <FormattedMessage
              id="Create Ticket"
              defaultMessage="Create Ticket"
            />
          ),
          type: "item",
          url: "/proponent/user-support/create",
          breadcrumbs: false,
          permissions: ["create-ticket"],
        },
        {
          id: "list_user_support",
          title: (
            <FormattedMessage id="List Tickets" defaultMessage="List Tickets" />
          ),
          type: "item",
          url: "/proponent/user-support/list",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "proponent_consumers",
      title: <FormattedMessage id="consumers" defaultMessage="Consumers" />,
      type: "item",
      url: "/proponent/consumers",
      icon: icons.IconUsers,
      breadcrumbs: false,
      permissions: ["view-consumers"],
    },
    {
      id: "proponent_stakeholders",
      title: <FormattedMessage id="stakeholders" defaultMessage="Stakeholders" />,
      type: "item",
      url: "/proponent/stakeholders",
      icon: icons.IconUserCircle,
      breadcrumbs: false,
      permissions: ["view-stakeholders"],
    },
    {
      id: "proponent_alerts",
      title: <FormattedMessage id="alerts" defaultMessage="Alerts" />,
      type: "item",
      url: "/proponent/alerts",
      icon: icons.IconAlertTriangle,
      breadcrumbs: false,
      permissions: ["view-stakeholders"],
    },
  ],
};

export default proponent;
