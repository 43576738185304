import { useMemo } from "react";
import useSWR, { mutate } from "swr";
import axiosServices from "utils/axios";
import { useCallback } from "react";

// Fetcher function for useSWR
const fetcher = (url) => axiosServices.get(url).then((res) => res.data);

// Hook to get user project
export function useGetProject(userId) {
  const endpoint = `/projects-service/projects/dmrv/${userId}`;

  const { data, error, isValidating } = useSWR(endpoint, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  const memoizedValue = useMemo(
    () => ({
      project: data,
      projectLoading: isValidating,
      error,
    }),
    [data, isValidating, error]
  );
  return memoizedValue;
}

// Hook to get all user projects
export function useGetProjects(userId) {
  const endpoint = `/projects-service/projects/dmrv/${userId}`;
  const { data, error, isValidating } = useSWR(endpoint, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  const memoizedValue = useMemo(
    () => ({
      projects: data,
      projectsLoading: isValidating,
      projectsError: error,
    }),
    [data, isValidating, error]
  );
  return memoizedValue;
}

// Hook to get project documents useGetProjectDocuments
export function useGetProjectDocuments(projectId) {
  const endpoint = projectId
    ? `/projects-service/documents/list?projectId=${projectId}`
    : null;

  const { data, error, isValidating } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false, // Disable revalidation on focus
    shouldRetryOnError: false, // Disable retries on error (general setting)
    onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
      // Specific retry logic on error
      if (retryCount >= 3) return; // Maximum of 3 retries
      if (error.status === 404) return; // Do not retry on 404 error
      setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000); // Retry after 5 seconds for other errors
    },
  });

  const memoizedValue = useMemo(
    () => ({
      projectDocuments: data,
      documentsLoading: isValidating,
      documentsError: error,
    }),
    [data, isValidating, error]
  );
  return memoizedValue;
}

// Hook to get devices
export function useGetDevices(projectId) {
  const endpoint = `/dmrv-mqtt-service/devices/${projectId}`;
  const { data, error, isValidating } = useSWR(endpoint, fetcher);
  return useMemo(
    () => ({
      devices: data,
      devicesLoading: isValidating,
      error,
    }),
    [data, isValidating, error]
  );
}

// Hook to get project data
export function useGetProjectData(projectId) {
  const endpoint = `/dmrv-service/api/v1/project-data/${projectId}`;
  const { data, error, isValidating } = useSWR(endpoint, fetcher);
  return useMemo(
    () => ({
      projectData: data,
      devicesLoading: isValidating,
      error,
    }),
    [data, isValidating, error]
  );
}

// Hook to get all project data
export function useGetAllProjectData(projectId) {
  const endpoint = `/dmrv-service/api/v1/all-project-data/${projectId}`;
  const { data, error, isValidating } = useSWR(endpoint, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  return useMemo(
    () => ({
      allProjectData: data,
      isLoading: isValidating,
      error,
    }),
    [data, isValidating, error]
  );
}

// Hook to add monitored parameters
export function useAddMonitoredParameters() {
  // const { mutate } = useSWRConfig();

  const addMonitoredParameters = useCallback(async (projectId, formData) => {
    const endpoint = `/dmrv-service/api/v1/project-data/${projectId}`;
    try {
      const response = await axiosServices.put(endpoint, formData);
      // Revalidate / update the local cache of monitored parameters
      // await mutate(`/dmrv-service/project-data/${projectId}`);
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      console.error(
        "Error adding monitored parameters:",
        error?.response?.data?.message || error.message
      );
      return {
        success: false,
        error:
          error?.response?.data?.message ||
          "Failed to add monitored parameters.",
      };
    }
  }, []);

  return { addMonitoredParameters };
}
